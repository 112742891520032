import './App.css';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import {lazy, Suspense} from 'react'
import {AuthContext} from '../../Context/AuthContext'
import { NotifProvider } from '../../Context/NotifContext'
import Navigation from '../../Components/Navigation/Navigation'
import Footer from '../../Components/Footer/Footer';
import { useContext, useEffect } from 'react';
import { ThemeContext } from '../../Context/ThemeContext';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { AppContext } from '../../Context/AppContext';

const Home = lazy(() => import('../Home/Home'))
const Profile = lazy(() => import('../Profile/Profile'))
const SignIn = lazy(() => import('../SignIn/SignIn'))
const NewInvoice = lazy(() => import('../NewInvoice/NewInvoice'))
const MyInvoices = lazy(() => import('../MyInvoices/MyInvoices'))
const MyClients = lazy(() => import('../MyClients/MyClients'))
const MyData = lazy(() => import('../MyData/MyData'))
const ContactUs = lazy(() => import('../ContactUs/ContactUs'))
const RenderInvoice = lazy(() => import('../RenderInvoice/RenderInvoice'))


function App() {

  const authCtx = useContext(AuthContext)

  const AppCtx = useContext(AppContext)
  const Theme = useContext(ThemeContext)

  useEffect(() => {
    if (Theme.Theme.isDark) {
      document.body.classList.add('Dark');
    } else {
      document.body.classList.remove('Dark');
    }
  }, [Theme.Theme])

  const Theme2 = createMuiTheme({
    palette:{
      type: Theme.Theme.isDark ? 'dark' : 'light'
    }
  })

  const validRender = () => {
    if (authCtx.auth.isUser) {
      if (AppCtx.app.renderInv) {
        return <RenderInvoice/>
      } else {
        return <Redirect to="/newinvoice"/>
      }
    } else {
      return <Redirect to="/signin"/>
    }
  }

  return (

      <ThemeProvider theme={Theme2}>
          <NotifProvider>
            <BrowserRouter>
              <Navigation/>
                <Switch>
                  <Suspense fallback={<div style={{textAlign:"center"}}>_</div>}>
                  <Route path="/profile">{authCtx.auth.isUser ? <Profile/> : <Redirect to="/signin"/>}</Route>
                  <Route path="/newinvoice">{authCtx.auth.isUser ? <NewInvoice/> : <Redirect to="/signin"/>}</Route>
                  <Route path="/myinvoices">{authCtx.auth.isUser ? <MyInvoices/> : <Redirect to="/signin"/>}</Route>
                  <Route path="/myclients">{authCtx.auth.isUser ? <MyClients/> : <Redirect to="/signin"/>}</Route>
                  <Route path="/renderinvoice">{validRender(authCtx.auth.isUser)}</Route>
                  <Route path="/contactus">{authCtx.auth.isUser ? <ContactUs/> : <Redirect to="/signin"/>}</Route>
                  <Route path="/mydata">{authCtx.auth.isUser ? <MyData/> : <Redirect to="/signin"/>}</Route>
                  <Route path="/signin"><SignIn/></Route>
                  <Route exact path="/"><Home/></Route>
                  </Suspense>
                </Switch>
              <Footer/>
            </BrowserRouter>
        </NotifProvider>
     </ThemeProvider>
  );
}

export default App;
