import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "./AppContext";
import {esES, enEN} from './Languages'

export const LanContext = React.createContext();

export const LanProvider = ({ children }) => {

    const App = useContext(AppContext)

    const [Lan, setLan] = useState({Lan:"esES", dic:esES})

    useEffect(() => {
        console.log('LanContext useEffect')
        if (App.app.myData.userLan === "enEN") {
            setLan((prev) => ({...prev, Lan:"enEN", dic:enEN}))
        } else {
            setLan((prev) => ({...prev, Lan:"esES", dic:esES}))
        }
    }, [App])

    return (
    <LanContext.Provider value={{Lan, setLan}}>
        {children}
    </LanContext.Provider>
  );
};