export const esES = {
    invMsg1: 'Fecha de emisión:',
    invMsg2: 'Fecha de vencimiento:',
    invMsg3: 'FACTURA',
    invMsg4: 'Número de factura',
    invMsg5: 'NIF',
    invMsg6: 'Concepto',
    invMsg7: 'Base UD.',
    invMsg8: 'Subtotal:',
    invMsg9: '% IVA:',
    invMsg10: '% IRPF:',
    invMsg11: 'TOTAL:',
    invCurrency: '€'
}

export const enEN = {
    invMsg1: 'Date:',
    invMsg2: 'Due:',
    invMsg3: 'INVOICE',
    invMsg4: 'Invoice number:',
    invMsg5: 'VAT',
    invMsg6: 'Concept',
    invMsg7: 'Price',
    invMsg8: 'Subtotal:',
    invMsg9: '% IVA:',
    invMsg10: '% IRPF:',
    invMsg11: 'TOTAL:',
    invCurrency: '£'
}