import React from 'react';
import ReactDOM from 'react-dom';
import App from './Pages/App/App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {AuthProvider} from './Context/AuthContext'
import {AppProvider} from './Context/AppContext'
import {ThemeProvider} from './Context/ThemeContext'
import {LanProvider} from './Context/LanContext'

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <AuthProvider>
        <ThemeProvider>
          <LanProvider>
            <App />
        </LanProvider>
        </ThemeProvider>
      </AuthProvider>
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
